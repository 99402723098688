import { environment } from './../../../../../environments/environment';

import { formUtil } from './../../../../shared/util/form-util';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder, AbstractControl, FormControl } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';

import { AddressValidation } from 'src/app/shared/directives/custom-validators.directive';

// Models
import { Submission, Address } from 'src/app/models/submission.model';
import { AddressSuggestedModel, AddressEventModel } from 'src/app/models/address.model';

// Services
import { SubmissionService } from 'src/app/services/submission.service';
import { AddressService } from 'src/app/services/address.service';
import { ScrollerService } from 'src/app/core/helpers/view-scroller.service';
import { AuthService } from 'src/app/services/auth.service';

// Constants
import { Countries } from 'src/app/shared/constants/lookup-countries';
import { lookupLists } from 'src/app/shared/constants/lookup-lists';
import { enums } from 'src/app/shared/enums/enums';

// Validators
import { dateIsInPastValidator } from 'src/app/shared/validators/dateIsInPast-validator.directive';

// Base Classes
import { BaseApplicationStep } from 'src/app/shared/base-classes/base-application-step';

// Utils
import { util } from 'src/app/shared/util/util';

import { finalize } from 'rxjs/operators';
import { customFunctionValidator } from 'src/app/shared/validators/customFunction-validator.directive';
import { regex } from 'src/app/shared/constants/regex';
import { GrantService } from 'src/app/services/grant.service';

// Extensions
import 'src/app/shared/util/extend-lookup';
import { ApplicationService } from '../../application.service';
import { ApplicationStep } from '../../application-step.enum';


@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss']
})

export class AddressDetailsComponent extends BaseApplicationStep implements OnInit {


  // ------------------------------------------------------
  //        Properties
  // ------------------------------------------------------



  model: Submission;

  titleImpactedAddress = 'Impacted Address';
  titleTemporaryAddress = 'Relocated Address';
  titleBestPostalAddress = 'Postal Address';
  enterAddressButtonLabel = 'Enter address manually';

  private loadingMessage_savingAddressDetails = 'Saving address details ...';

  addresses: AddressSuggestedModel[] = [];
  addressesLoading = false;
  addressesNoData = false;
  addressSelected: AddressSuggestedModel;
  disableAddressFields = true;
  addressErrorMessage = '';

  private isImpactedAddressManuallyEntered = false;

  public maxImpactDate: Date;

  /** Array of address suggestions found for manualy entered address */
  addressSuggestions: AddressSuggestedModel[] = [];


  // ------------------
  //    Lookup list data

  lookup_auStates = lookupLists.australianStates;
  countries = Countries;

  // --------------------
  //    Forms

  fgModel_impactedAddress = {
    // form fields
    propertyTypeId: ['', Validators.required],
    inputAddress: [''],
    fullAddress: [''],
    // database fields
    streetNumber: [{ value: '', disabled: true }],
    streetName: [{ value: '', disabled: true }],
    suburb: [{ value: '', disabled: true }],
    postcode: [{ value: '', disabled: true }],
    state: [{ value: 'QLD', disabled: true }],
    country: ['Australia'],
    lotNumber: [{ value: '', disabled: true }, [Validators.maxLength(5), Validators.pattern(regex.alphaNumeric)]],
    unitNumber: [{ value: '', disabled: true }, [Validators.maxLength(5), Validators.pattern(regex.alphaNumeric)]],
    marinaDockName: [''],
    mooringNumber: [''],
    boatName: [''],
    geoX: [''],
    geoY: [''],
    poBoxNumber: [''],
    lga: [''],
    isPrimaryResidence: [null, Validators.required],
    wasManuallyEntered: ['']
  };

  fgModel_temporaryAddress = {
    propertyTypeId: [''],
    streetNumber: [''],
    streetName: [''],
    suburb: [''],
    postcode: [''],
    state: [''],
    country: ['Australia'],
    lotNumber: ['', [Validators.maxLength(5), Validators.pattern(regex.alphaNumeric)]],
    unitNumber: ['', [Validators.maxLength(5), Validators.pattern(regex.alphaNumeric)]],
    marinaDockName: [''],
    mooringNumber: [''],
    boatName: [''],
    internationalProvince: ['', [Validators.pattern(regex.alphaNumericWithSpaces)]],
  };

  fgModel_otherAddress = {
    propertyTypeId: [''],
    poBoxNumber: [''],
    streetNumber: [''],
    streetName: [''],
    suburb: [''],
    postcode: [''],
    state: [''],
    country: [''],
    lotNumber: ['', [Validators.maxLength(5), Validators.pattern(regex.alphaNumeric)]],
    unitNumber: ['', [Validators.maxLength(5), Validators.pattern(regex.alphaNumeric)]],
    marinaDockName: [''],
    mooringNumber: [''],
    boatName: [''],
    internationalProvince: ['', [Validators.pattern(regex.alphaNumericWithSpaces)]],
  };

  fgModel_form = {

    // form fields
    bestPostalAddressTypeId: [null, Validators.required],

    // database fields
    dateOfImpact: [null, [Validators.required, dateIsInPastValidator(true)]],
    isAtTemporaryAddress: [null, Validators.required],
    isStrandedTraveller: [null, Validators.required],
    isEvacuated: [null, Validators.required],
    isHomeOwner: [null, Validators.required],

    impactedAddress: this.fb.group({}),
    temporaryAddress: this.fb.group({}),
    otherAddress: this.fb.group({})
  };

  form: FormGroup;

  // ---------------------
  //  Validators

  private vdtr_impactedAddressSupplied =
    customFunctionValidator((): boolean => {
      return (this.fImpactedAddress.streetName.value
        && this.fImpactedAddress.suburb.value
        && this.fImpactedAddress.postcode.value
        && this.fImpactedAddress.state.value);
    }, 'invalidAddress');


  // ---------------
  //    Getters

  get f() { return this.form.controls; }
  get fImpactedAddress() { return (this.f.impactedAddress as FormGroup).controls; }
  get fTempAddress() { return (this.f.temporaryAddress as FormGroup).controls; }
  get fOtherAddress() { return (this.f.otherAddress as FormGroup).controls; }

  get lookup_propertyTypesWithoutPOBox() {
    return this.lookup_propertyTypes.filter((item) => item.name.toLowerCase().replace(' ', '') !== 'pobox');
  }
  get lookup_propertyTypesWithoutImpactedAndPOBox() {
    return this.lookup_propertyTypes.filter((item) => item.name.toLowerCase().replace(' ', '') !== 'pobox' && item.name.toLowerCase().replace(' ','') !== 'impacted' && item.name.toLowerCase().replace(' ','') !== 'cycloneshelter');
  }
  get dateOfImpact() { return this.f.dateOfImpact.value; }
  get isAtTemporaryAddress(): boolean { return this.f.isAtTemporaryAddress.value; }
  get bestPostalAddressTypeId() { return this.f.bestPostalAddressTypeId.value; }
  get isBestPostalAddressOther(): boolean { return Number(this.f.bestPostalAddressTypeId.value) === enums.addressTypes.Other; }
  // impacted
  get isImpactedPropertyTypeSelected(): boolean { return util.hasValue(this.fImpactedAddress.propertyTypeId.value); }
  get isImpactedPropertyUnit() {
    const propertyType = Number(this.fImpactedAddress.propertyTypeId.value);
    return propertyType === enums.propertyTypes.Unit
      || propertyType === enums.propertyTypes.AgedCare
      || propertyType === enums.propertyTypes.Caravan
      || propertyType === enums.propertyTypes.RetirementVillage;
  }
  get isImpactedPropertyBoat(): boolean { return Number(this.fImpactedAddress.propertyTypeId.value) === enums.propertyTypes.Boat; }
  get isImpactedPropertyHouse(): boolean { return Number(this.fImpactedAddress.propertyTypeId.value) === enums.propertyTypes.House; }
  // Temporary address properties
  get isTemporaryPropertyNotSelected() { return this.fTempAddress.propertyTypeId.value === ''; }
  get isTemporaryPropertyUnit() {
    const propertyType = Number(this.fTempAddress.propertyTypeId.value);
    return propertyType === enums.propertyTypes.Unit
      || propertyType === enums.propertyTypes.AgedCare
      || propertyType === enums.propertyTypes.Caravan
      || propertyType === enums.propertyTypes.RetirementVillage;
  }
  get isTemporaryPropertyBoat(): boolean { return Number(this.fTempAddress.propertyTypeId.value) === enums.propertyTypes.Boat; }
  get isTemporaryPropertyHouse(): boolean { return Number(this.fTempAddress.propertyTypeId.value) === enums.propertyTypes.House; }
  get isTemporaryInAustralia(): boolean { return this.fTempAddress.country.value === 'Australia'; }
  // Other address properties
  get isOtherPropertyNotSelected() { return this.fOtherAddress.propertyTypeId.value === ''; }
  get isOtherPropertyUnit() {
    const propertyType = Number(this.fOtherAddress.propertyTypeId.value);
    return propertyType === enums.propertyTypes.Unit
      || propertyType === enums.propertyTypes.AgedCare
      || propertyType === enums.propertyTypes.Caravan
      || propertyType === enums.propertyTypes.RetirementVillage;
  }
  get isOtherPropertyBoat(): boolean { return Number(this.fOtherAddress.propertyTypeId.value) === enums.propertyTypes.Boat; }
  get isOtherPropertyHouse(): boolean { return Number(this.fOtherAddress.propertyTypeId.value) === enums.propertyTypes.House; }
  get isOtherPropertyPOBox(): boolean { return Number(this.fOtherAddress.propertyTypeId.value) === enums.propertyTypes.POBox; }
  get isOtherInAustralia(): boolean { return this.fOtherAddress.country.value === 'Australia'; }

  isDisabled() {
    if (this.f.bestPostalAddressTypeId.value === enums.addressTypes.Temporary) { return false; } else { return null; }
  }

  getPopOver() {
    if (this.f.bestPostalAddressTypeId.value === enums.addressTypes.Temporary) { return 'Enter a temporary address.'; }
    return;
  }


  // ------------------------------------------------------
  //    Constructor
  // ------------------------------------------------------

  constructor(
    private fb: FormBuilder,
    private addressService: AddressService,
    // NOTE: Do not remove grantService. It is used eventhough vs code does not recognize it,
    // because used by context reference self in callback method. Refer below to self.grantService
    private grantService: GrantService,
    appSvc: ApplicationService,
    submissionService: SubmissionService,
    authService: AuthService,
    scrollerService: ScrollerService
  ) {
    super(ApplicationStep.AddressDetails, appSvc, submissionService, authService, scrollerService);
  }

  // ------------------------------------------------------
  //    Methods
  // ------------------------------------------------------


  ngOnInit() {

    this.initForm();

    // Set maximum clickable date on the date picker for date of impact
    this.maxImpactDate = new Date();
    this.maxImpactDate.setDate(new Date().getDate());
  }

  initForm() {
    this.form = this.fb.group(this.fgModel_form);
    this.f.impactedAddress = this.fb.group(this.fgModel_impactedAddress);
    this.f.temporaryAddress = this.fb.group(this.fgModel_temporaryAddress);
    this.f.otherAddress = this.fb.group(this.fgModel_otherAddress);

    // attach validators

    formUtil.validation.toggleControlValidators(this.fImpactedAddress.inputAddress, [this.vdtr_impactedAddressSupplied]);

    // property type change - Impacted Address
    this.fImpactedAddress.propertyTypeId.valueChanges.subscribe(
      (type: string) => {

           // Boat
          const isBoat = (Number(type) === enums.propertyTypes.Boat);
          formUtil.validation.toggleRequired(this.fImpactedAddress.boatName, isBoat);
          formUtil.validation.toggleRequired(this.fImpactedAddress.mooringNumber, isBoat);

          // Unit No is required if the propertyselected is Unit/Flat/appartment/Townhouse and the unit no is NULL
          const isUnit = (Number(type) === enums.propertyTypes.Unit);
          formUtil.validation.toggleRequired(this.fImpactedAddress.unitNumber, isUnit);

      }
    );

    this.fTempAddress.propertyTypeId.valueChanges.subscribe(
      (type: string) => {

        // Boat
          const isBoat = (this.isAtTemporaryAddress && Number(type) === enums.propertyTypes.Boat);
          formUtil.validation.toggleRequired(this.fTempAddress.boatName, isBoat);
          formUtil.validation.toggleRequired(this.fTempAddress.mooringNumber, isBoat);

          // Unit No is required if the propertyselected is Unit/Flat/appartment/Townhouse and the unit no is NULL
          const isUnit = (this.isAtTemporaryAddress && Number(type) === enums.propertyTypes.Unit);
          formUtil.validation.toggleRequired(this.fTempAddress.unitNumber, isUnit);
          this.fTempAddress['unitNumber'].enable();


      }
    );

    this.fOtherAddress.propertyTypeId.valueChanges.subscribe(
      (type: string) => {

        this.toggleAddressValidators(this.f.otherAddress as FormGroup, true);
      }
    );

    // input address change :: TypeAhead method to find address with partial address
    this.fImpactedAddress.inputAddress.valueChanges.subscribe(
      (partial: string) => {
        this.addressService.findAddressByInputAddress(partial)
          .subscribe(suggestions => {
            if (suggestions) {
              this.addresses = suggestions;
            } else {    // No matching addresses found for the partial address typed
              this.addresses = [];
            }
          });
      });

    // isAtTemporaryAddress change
    this.f.isAtTemporaryAddress.valueChanges.subscribe((isAtTempAddress: boolean) => {
      this.toggleAddressValidators(this.f.temporaryAddress as FormGroup, isAtTempAddress);
      formUtil.validation.toggleRequired(this.fTempAddress.propertyTypeId, isAtTempAddress);

    });

    // country change - Temporary
    this.fTempAddress.country.valueChanges.subscribe(
      (c: string) => {
        // Turn required validators on if Temporary address is true and for Australia only, off for other countries
        const isAustralia = (this.isAtTemporaryAddress && c === 'Australia');
        formUtil.validation.toggleRequired(this.fTempAddress.suburb, isAustralia);
        formUtil.validation.toggleRequired(this.fTempAddress.state, isAustralia);
        formUtil.validation.toggleRequired(this.fTempAddress.postcode, isAustralia);


      });

    // country change - Other
    this.fOtherAddress.country.valueChanges.subscribe(
      (c: string) => {
        // Turn required validators on if Other Address and for Australia only, off for other countries
        const isAustralia = (this.isBestPostalAddressOther && c === 'Australia');
        formUtil.validation.toggleRequired(this.fOtherAddress.suburb, isAustralia);
        formUtil.validation.toggleRequired(this.fOtherAddress.state, isAustralia);
        formUtil.validation.toggleRequired(this.fOtherAddress.postcode, isAustralia);



      });
  }


  getAddressGeocodeFromEsb(addressSuggestion: AddressSuggestedModel, onSuccess: Function = null) {
    this.addressSelected = addressSuggestion;
    // Retrieve the address details using the magic key
    this.addressErrorMessage = '';
    this.addressService.getAddressDetails(addressSuggestion.text, addressSuggestion.magicKey)
      .subscribe(
        (addresses => {
          if (addresses) {
            //updated fields mapping to the geocoder object returned directly from arc gis
            if (environment.bypassESB) {
              const address = addresses['candidates'][0]['attributes'];   // Gets first address from response
              const street = (address.stName == null ? '' : address.stName) + ' ' + (address.stType == null ? '' : address.stType) +
                (address.stDir !== null && address.stDir !== undefined ? ' ' + address.stDir : '');
              this.fImpactedAddress.fullAddress.setValue(address.match_Addr);
              this.fImpactedAddress.streetName.setValue(street);
              this.fImpactedAddress.suburb.setValue(address.city);
              this.fImpactedAddress.postcode.setValue(address.postal);
              this.fImpactedAddress.state.setValue(address.regionAbbr);
              this.fImpactedAddress.streetNumber.setValue(address.addNum);
              this.fImpactedAddress.unitNumber.setValue(address.subAddUnit);
              // Store Geo codes to retrieve the available polygons for the address - used when submit
              this.fImpactedAddress.geoX.setValue(address.x);
              this.fImpactedAddress.geoY.setValue(address.y);
              this.fImpactedAddress.lga.setValue(address.lga_Name)
              // Revalidate the address
              this.fImpactedAddress.inputAddress.updateValueAndValidity();
              this.addressErrorMessage = '';
              // validate UNIT NO is there
              this.validateUnitNo(this.fImpactedAddress['unitNumber'].value);
            } else {
              const address = addresses[0];   // Gets first address from response
              const street = (address.streetName == null ? '' : address.streetName) + ' ' + (address.streetType == null ? '' : address.streetType) +
                (address.streetDirection !== null && address.streetDirection !== undefined ? ' ' + address.streetDirection : '');
              this.fImpactedAddress.fullAddress.setValue(address.address);
              this.fImpactedAddress.streetName.setValue(street);
              this.fImpactedAddress.suburb.setValue(address.locality);
              this.fImpactedAddress.postcode.setValue(address.postcode);
              this.fImpactedAddress.state.setValue(address.state);
              this.fImpactedAddress.streetNumber.setValue(address.house);
              this.fImpactedAddress.unitNumber.setValue(address.unitNumber);
              // Store Geo codes to retrieve the available polygons for the address - used when submit
              this.fImpactedAddress.geoX.setValue(address.geocode.x);
              this.fImpactedAddress.geoY.setValue(address.geocode.y);
              // Revalidate the address
              this.fImpactedAddress.inputAddress.updateValueAndValidity();
              this.addressErrorMessage = '';
              // validate UNIT NO is there
              this.validateUnitNo(this.fImpactedAddress['unitNumber'].value);
            }

          } else {
            // tslint:disable-next-line: max-line-length
            this.addressErrorMessage = this.addressSelected.text + ' was not found, please retype your address or click on the Enter address manually button.';
          }
          if (onSuccess) {
            onSuccess(this);

          }
        })
      );
  }

  getAddressFromFormFields(): string {

    // get impacted address model from form
    const a = this.form.getRawValue().impactedAddress as Address;
    let address = '';

    // get address parts
    const streetNumber = (a.streetNumber ? a.streetNumber + ' ' : '');
    const streetName = (a.streetName ? a.streetName + ', ' : '');
    const suburb = (a.suburb ? a.suburb + ', ' : '');
    const postcode = (a.postcode ? a.postcode + ' ' : '');
    const state = (a.state ? a.state + ' ' : '');
    const country = (a.country ? a.country + ' ' : '');
    const lotNumber = (a.lotNumber ? a.lotNumber + ' ' : '');
    const unitNumber = (a.unitNumber ? a.unitNumber + '/' : '');
    const marinaDockName = (a.marinaDockName ? a.marinaDockName + ' ' : '');
    const mooringNumber = (a.mooringNumber ? a.mooringNumber + ' ' : '');
    const boatName = (a.boatName ? a.boatName + ' ' : '');

    // boat
    // if (this.isImpactedPropertyBoat) {
    //   address += (`${marinaDockName}${a.mooringNumber}${a.boatName}`);
    // }

    // unit
    // if(this.isImpactedPropertyUnit){
    //   address += unitNumber;
    // }

    // main address part
    address += `${streetNumber}${streetName}${suburb}${state}`; // + postcode

    // if (this.isImpactedPropertyHouse) {
    //   address += lotNumber;
    // }

    return address;
  }


  onAddressSelect(event: TypeaheadMatch): void {

    this.getAddressGeocodeFromEsb(event.item);
  }

  onSelect_ManuallyEnteredAddressSuggestion(event) {
    const suggestedAddressAndMagicKey: string = event.target.value;

    // if one of the sugested addresses is selected
    // find geocodes for the suggested address and then find grants for those geocodes
    if (suggestedAddressAndMagicKey) {
      // parse pipe separated addres|magicKey pair
      const parts = suggestedAddressAndMagicKey.split('|');
      if (parts.length > 1) {
        const text = parts[0];
        const magicKey = parts[1];
        const suggestedAddressModel = new AddressSuggestedModel({ text: text, magicKey: magicKey });

        // get polygons geocodes, find grants and save address
        this.getAddressGeocodeFromEsb(suggestedAddressModel, this.findPolygonIds);
      } else {
        this.scrollToTop();
        //this.saveChanges(true, false, this.loadingMessage_savingAddressDetails);
      }
    }
  }

  validateUnitNo(unitno: string) {
    if (unitno === null) {
      this.fImpactedAddress['unitNumber'].enable();
    } else {
      this.fImpactedAddress['unitNumber'].disable();
    }
  }

  /**
   * Manually progress form. This should only be used in complex cases where we need to do somethign extra befor saving form data.
   * In this case we need to make an ajax call to server to get polygon Ids
   * and we need to stop form submission until we receive the response with polygonIds.
   */
  onClick_Next() {

    this.hasAttemptedToProgress = true;

    // if form valid continue
    if (this.isFormValid) {

      // if address manually added - call address service to try and get AddressSuggestedModel with magic key
      if (this.isImpactedAddressManuallyEntered) {

        // assemble searchable address string from manually filled in form fields
        const address = this.getAddressFromFormFields();

        // call address service
        this.addressService.findAddressByInputAddress(address)
          .subscribe((suggestions: AddressSuggestedModel[]) => {

            // Matching addresses found for the partial address typed
            if (suggestions) {
              this.addressSuggestions = suggestions;


              // Address not found
            } else {
              this.fImpactedAddress.geoX.setValue(null);
              this.fImpactedAddress.geoY.setValue(null);
              this.fImpactedAddress.lga.setValue(null);
              this.findPolygonIds(this);

            }
          });

      } else {
        this.findPolygonIds(this);
      }

    }
  }

  findPolygonIds(context = null) {

    const self = (context ? context : this);
    const formModel = self.form.getRawValue();
    const geoX = formModel.impactedAddress.geoX;
    const geoY = formModel.impactedAddress.geoY;

    self.model.polygonIds = null;

    self.scrollToTop();

    // if no coordinates provided, just save changes
    if (!geoX || !geoY) {

      self.onAfterFindPolygonIds(self);

      // if coordinates provided get polygon ids first and then save changes
    } else {

      self.appService.showLoading(self.loadingMessage_savingAddressDetails);
      // get polygon ids from server
      self.addressService.getPolygonIds(geoX, geoY)
        .subscribe(

          // onSuccess
          (polygons) => {
//console.log('xxx' + polygons);
            // There are events for the given address and date
            if (polygons) {

              // assign polyigonIds to model
              const polyIds = [];
              if(!environment.bypassESB){
                polygons.forEach(polygon => {
                  const polygonId = polygon.objectId;
                  polyIds.push(polygonId);
                });
              } else {
                polygons.forEach(polygon => {
                  //console.log(polygon.attributes.OBJECTID);
                  const polygonId = polygon.attributes.objectId;
                  polyIds.push(polygonId);
                 // console.log(polyIds);
                });
              }


              self.model.polygonIds = polyIds;

              // There are no events for the given addres and date, therefore grants can not be requested
            } else {
              self.model.polygonIds = null;
              self.model.eventId = null;
              self.model.requestsNoGrants = null;
              self.appService.hideLoading();
            }

            self.onAfterFindPolygonIds(self);

          },

          // onError
          () => {
            // remove loading indicator on error
            // NOTE: No need to remove loading indicator on finalize, because it will be removed inside saveChanges call
            self.appService.hideLoading();
            self.model.polygonIds = null;
            self.model.eventId = null;
            self.model.requestsNoGrants = null;
            self.onAfterFindPolygonIds(self);
          }
        )
    }
  }

  onAfterFindPolygonIds(context: AddressDetailsComponent) {

    // if no polygons found, remove ID Docs, Partner and Dependants if they were previously added.
    if (context.model.polygonIds == null) {

      // ASYNC: Remove Submission Persons and their documents
      // this.submissionService.submissionPersons_remove(() => {

      // this.submissionService.removeInheritedDetails_fromModel();

      // ASYNC: Save submission to database
      context.saveChanges(true, false, context.loadingMessage_savingAddressDetails);
      // });

    } else {
      // ASYNC: Save submission to database
      context.saveChanges(true, false, context.loadingMessage_savingAddressDetails);
    }
  }


  // manually navigate to next step after the changes have been saved to the server succesfully
  wizStep_onSaveSuccess() {
    this.appService.goForward();
  }

  onChange_bestPostalAddressType(event, index) {

    const addressType = this.lookup_addressTypes[index];
    const isTemporarySelected = (addressType.id === enums.addressTypes.Temporary);
    const isOtherSelected = (addressType.id === enums.addressTypes.Other);

    // if temporary selected as postal, automatically answer Yes to are you at temporary address. this will also set validators
    if (isTemporarySelected) {
      this.f.isAtTemporaryAddress.setValue(true);
    }

    // toggle other
    formUtil.validation.toggleRequired(this.fOtherAddress.propertyTypeId, isOtherSelected);
    this.toggleAddressValidators(this.f.otherAddress as FormGroup, isOtherSelected);
  }

  toggleAddressValidators(addressFormGroup: FormGroup, on: boolean, isImpactedAddress: boolean = false) {
    
    // if isImpacted address then postcode is validated as australian postcode, otherwise it is validated as international postcode
    const vdtr_postcode = (isImpactedAddress ?
      [Validators.required, Validators.maxLength(4), Validators.pattern(regex.numeric)] :
      [Validators.required, Validators.maxLength(11), Validators.pattern(regex.alphaNumeric)]);

    const vdtr_unitno = ((this.isTemporaryPropertyUnit || this.isImpactedPropertyUnit || this.isOtherPropertyUnit) ?
      [Validators.required, Validators.maxLength(5), Validators.pattern(regex.alphaNumeric)] : []);

    // if PO Box selected for other Property selection then validate PO Box Number otherwise validate Street Number and Name
    const vdtr_poboxnumber = (this.isOtherPropertyPOBox ?
      [Validators.required, Validators.maxLength(50), Validators.pattern(regex.alphaNumericWithSpaces)] :
      null);

     const vdtr_streetnumber = (this.isOtherPropertyPOBox ?
       null :
       [Validators.required, Validators.maxLength(6), Validators.pattern(regex.alphaNumeric)]);

     const vdtr_streetname = (this.isOtherPropertyPOBox ?
      null :
      [Validators.required, Validators.pattern(regex.alphaNumericWithSpaces)]);

    formUtil.validation.toggleControlsValidators(addressFormGroup, {
      streetNumber: vdtr_streetnumber,
      streetName: vdtr_streetname,
      suburb: [Validators.required, Validators.pattern(regex.alphaNumericWithSpaces)],
      postcode: vdtr_postcode,
      state: [Validators.required],
      country: [Validators.required],
      poBoxNumber: vdtr_poboxnumber,
      unitNumber: vdtr_unitno,
    }, on);
  }

  changeAddressesLoading(e: boolean): void {
    this.addressesLoading = e;
  }

  addressesNoResults(e: boolean): void {
    this.addressesNoData = e;
  }

  toggleAddressFields(isManuallyEntered: boolean = null) {

    // remove any address errors
    this.addressErrorMessage = '';

    // toggle current status of manual entry address fields
    if (isManuallyEntered != null) {
      this.disableAddressFields = !isManuallyEntered;
    } else {
      this.disableAddressFields = !this.disableAddressFields;
    }

    const manualAddressFieldStatus = this.disableAddressFields ? 'disable' : 'enable';
    const autoAddressFieldStatus = this.disableAddressFields ? 'enable' : 'disable';
    this.enterAddressButtonLabel = this.disableAddressFields ? 'Enter address manually' : 'Find Automatically';

    // set manual address input fields
    this.fImpactedAddress.streetName[manualAddressFieldStatus]();
    this.fImpactedAddress.suburb[manualAddressFieldStatus]();
    this.fImpactedAddress.postcode[manualAddressFieldStatus]();
    this.fImpactedAddress.streetNumber[manualAddressFieldStatus]();
    this.fImpactedAddress.lotNumber[manualAddressFieldStatus]();
    this.fImpactedAddress.unitNumber[manualAddressFieldStatus]();
    const enableManualFieldsValidators = !this.disableAddressFields;
    this.toggleAddressValidators(this.f.impactedAddress as FormGroup, enableManualFieldsValidators, true);

    // set auto address input field
    this.fImpactedAddress.inputAddress[autoAddressFieldStatus]();
    // reset address search field, if manual address fields are enabled
    if (!this.disableAddressFields) {
    }
    // if value is not passed for isManuallyEntered, then derive it from toggle action
    if (isManuallyEntered == null) {
      this.isImpactedAddressManuallyEntered = !this.disableAddressFields;
    }
  }



  // -----------------------------------------
  //  Abstract method implementations


  bindModelToForm() {

    formUtil.mapModelToForm(this.model, this.f, {
      // must convert date string to date object to bind it to control
      dateOfImpact: function (val) { return (util.hasValue(val) ? new Date(val) : null); }
    });

    // -------------------
    //  Impacted Address
    // -------------------
    const impactedAddress = this.model.addresses.find(a => a.addressTypeId === enums.addressTypes.Impacted);
    if (impactedAddress) {
      // get state display code for the given id
      const state = lookupLists.australianStates.find(s => s.value === impactedAddress.state);
      impactedAddress.state = (state ? state.code : 'QLD');
      formUtil.mapModelToForm(impactedAddress, this.fImpactedAddress);
      this.isImpactedAddressManuallyEntered = impactedAddress.wasManuallyEntered;
      this.toggleAddressFields(this.isImpactedAddressManuallyEntered);
    }

    // -------------------
    //  Temporary Address
    // -------------------
    const temporaryAddress = this.model.addresses.find(a => a.addressTypeId === enums.addressTypes.Temporary);
    if (temporaryAddress) {
      formUtil.mapModelToForm(temporaryAddress, this.fTempAddress);
      this.toggleAddressValidators(this.f.temporaryAddress as FormGroup, true);



    }

    // -------------------
    //  Other Address
    // -------------------
    const otherAddress = this.model.addresses.find(a => a.addressTypeId === enums.addressTypes.Other);
    if (otherAddress) {
      formUtil.mapModelToForm(otherAddress, this.fOtherAddress);
      // Set validators for other address
      this.toggleAddressValidators(this.f.otherAddress as FormGroup, true);
    }

    // set best postal address
    this.f.bestPostalAddressTypeId.setValue((
      impactedAddress && impactedAddress.isPostal ? enums.addressTypes.Impacted :
        temporaryAddress && temporaryAddress.isPostal ? enums.addressTypes.Temporary :
          otherAddress && otherAddress.isPostal ? enums.addressTypes.Other :
            null
    ));
  }


  // --------------------------------------------------

  bindFormToModel() {

    // continue with address details processing...
    const formModel = this.form.getRawValue();
    const isPostalImpacted = (this.bestPostalAddressTypeId === enums.addressTypes.Impacted);
    const isPostalTemporary = (this.bestPostalAddressTypeId === enums.addressTypes.Temporary);
    const isPostalOther = (this.bestPostalAddressTypeId === enums.addressTypes.Other);
    const wasManuallyEntered_false = false;
    const wasManuallyEntered_true = true;

    // Map : dateOfImpact, isAtTemporaryAddress, isStrandedTraveller
    util.mapTo(this.model, formModel, true);
    // Map bestPostalAddressType and bestPostalAddressTypeId to model
    this.model.bestPostalAddressTypeId = this.bestPostalAddressTypeId;
    this.model.bestPostalAddressType = this.lookup_addressTypes.GetTextFromId(this.bestPostalAddressTypeId);

    // Map : Impacted, Temporary and Other Address
    // clear existing address data
    this.model.addresses = [];

    // -------------------
    //  Impacted Address
    // -------------------

    const impactedAddress = this.getAddressModel(formModel.impactedAddress,
      enums.addressTypes.Impacted,
      +formModel.impactedAddress.propertyTypeId,
      this.isImpactedAddressManuallyEntered,
      isPostalImpacted);
    this.model.addresses.push(impactedAddress);

    // -------------------
    //  Temporary Address
    // -------------------

    if (this.model.isAtTemporaryAddress) {
      const temporaryAddress = this.getAddressModel(formModel.temporaryAddress,
        enums.addressTypes.Temporary,
        +formModel.temporaryAddress.propertyTypeId,
        wasManuallyEntered_true,
        isPostalTemporary);
      this.model.addresses.push(temporaryAddress);
    }

    // -------------------
    //  Other Address
    // -------------------

    if (this.bestPostalAddressTypeId === enums.addressTypes.Other) {
      const otherAddress = this.getAddressModel(
        formModel.otherAddress,
        enums.addressTypes.Other,
        +formModel.otherAddress.propertyTypeId,
        wasManuallyEntered_true,
        isPostalOther);
      this.model.addresses.push(otherAddress);
    }

    // Set to integrate Submission with CRM
    this.model.integrateWithCRM = true;
  }

  /**
   * Maps form address model to new Address model
   */
  private getAddressModel(formModel: any, addressTypeId: number, propertyTypeId: number, wasManuallyEntered: boolean, isPostal: boolean) {

    // address type property prefix
    const isImpactedAddress = (addressTypeId === enums.addressTypes.Impacted);
    const isAustralianAddress = (formModel.country === 'Australia');
    const isUnit = propertyTypeId === enums.propertyTypes.Unit
      || propertyTypeId === enums.propertyTypes.AgedCare
      || propertyTypeId === enums.propertyTypes.Caravan
      || propertyTypeId === enums.propertyTypes.RetirementVillage;
    const isHouse = propertyTypeId === enums.propertyTypes.House;
    const isBoat = propertyTypeId === enums.propertyTypes.Boat;
    const isPOBox = propertyTypeId === enums.propertyTypes.POBox;

    // create object, map data from form and return it
    return new Address({

      addressTypeId: addressTypeId,
      addressType: this.lookup_addressTypes.GetTextFromId(addressTypeId),
      propertyTypeId: propertyTypeId,
      propertyType: this.lookup_propertyTypes.GetTextFromId(propertyTypeId),

      // if impactedaddres : get state id for the given display code.
      state: (isImpactedAddress ? lookupLists.australianStates.find(s => s.code === formModel.state).value : formModel.state),
      postcode: formModel.postcode,
      suburb: formModel.suburb,
      streetName: formModel.streetName,
      streetNumber: formModel.streetNumber,
      country: formModel.country,

      isMatched: null,
      isActivated: null,
      wasManuallyEntered: wasManuallyEntered,
      isPostal: isPostal,

      unitNumber: (isUnit ? formModel.unitNumber : null),
      lotNumber: (isHouse ? formModel.lotNumber : null),
      mooringNumber: (isBoat ? formModel.mooringNumber : null),
      marinaDockName: (isBoat ? formModel.marinaDockName : null),
      boatName: (isBoat ? formModel.boatName : null),

      poBoxNumber: (isPOBox ? formModel.poBoxNumber : null),

      // fields only provided for impacted address
      lga: (isImpactedAddress ? formModel.lga : null),
      geoX: (isImpactedAddress ? formModel.geoX : null),
      geoY: (isImpactedAddress ? formModel.geoY : null),

      // International Province only provided for temporary and other addresses when another country, not Australia, is selected
      internationalProvince: (isImpactedAddress || isAustralianAddress ? null : formModel.internationalProvince),

      isPrimaryResidence: (isImpactedAddress ? formModel.isPrimaryResidence : null),
    });
  }



  // --------------
  // Form Validation Css

  getFldClass_impacted(fieldName: string) {
    const control = this.fImpactedAddress[fieldName];
    return formUtil.validation.getCssClass_invalidInputControl(control, this.hasAttemptedToProgress);
  }
  getFldClass_inputGrp_impacted(fieldName: string) {
    const control = this.fImpactedAddress[fieldName];
    return formUtil.validation.getCssClass_invalidInputGroup(control, this.hasAttemptedToProgress);
  }
  getFldClass_temporary(fieldName: string) {
    const control = this.fTempAddress[fieldName];
    return formUtil.validation.getCssClass_invalidInputControl(control, this.hasAttemptedToProgress);
  }
  getFldClass_other(fieldName: string) {
    const control = this.fOtherAddress[fieldName];
    const cls = formUtil.validation.getCssClass_invalidInputControl(control, this.hasAttemptedToProgress);
    return cls;
  }



}
